
import request from '@/utils/request'

export function fetchSignLog(params) {
  return request({
    url: '/HrSignLog/Search',
    method: 'post',
    data: params
  });
}

export function fetchEmployee(params) {
  return request({
    url: '/HrEmployee/Search',
    method: 'post',
    data: params
  });
}

export function delEmployee(id) {
  return request({
    url: '/HrEmployee/' + id,
    method: 'delete'
  });
}
