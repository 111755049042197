<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>Hr系统</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="打卡时间" label-width="90px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:sourceType="scope">
        <span style="color:#79bbff" v-if="scope.row.sourceType == 'ATM'">考勤机打卡</span>
        <span style="color:#79bbff" v-else-if="scope.row.sourceType == 'DING_ATM'">钉钉考勤机</span>
        <span style="color:#79bbff" v-else-if="scope.row.sourceType == 'USER'">用户打卡</span>
        <span style="color:#79bbff" v-else-if="scope.row.sourceType == 'BOSS'">老板改签</span>
        <span style="color:#79bbff" v-else-if="scope.row.sourceType == 'APPROVE'">审批系统</span>
        <span style="color:#79bbff" v-else-if="scope.row.sourceType == 'SYSTEM'">考勤系统</span>
        <span style="color:#79bbff" v-else-if="scope.row.sourceType == 'AUTO_CHECK'">自动打卡</span>
        <span v-else>未知</span>
      </template>
      <template v-slot:timeResult="scope">
        <span style="color:#67c23a" v-if="scope.row.timeResult == 'Normal'">正常</span>
        <span style="color:#f56c6c" v-else-if="scope.row.timeResult == 'Early'">早退</span>
        <span style="color:#e6a23c" v-else-if="scope.row.timeResult == 'Late'">迟到</span>
        <span style="color:#f56c6c" v-else-if="scope.row.timeResult == 'SeriousLate'">严重迟到</span>
        <span style="color:#f56c6c" v-else-if="scope.row.timeResult == 'Absenteeism'">旷工迟到</span>
        <span style="color:#f56c6c" v-else-if="scope.row.timeResult == 'NotSigned'">未打卡</span>
        <span v-else>未知</span>
      </template>
      <template v-slot:locationResult="scope">
        <span style="color:#67c23a" v-if="scope.row.locationResult == 'Normal'">范围内</span>
        <span style="color:#f56c6c" v-else-if="scope.row.locationResult == 'Outside'">范围外</span>
        <span style="color:#f56c6c" v-else-if="scope.row.timeResult == 'NotSigned'">未打卡</span>
        <span v-else>未知</span>
      </template>
      <template v-slot:checkType="scope">
        <span style="color:#67c23a" v-if="scope.row.checkType == 'OnDuty'">上班</span>
        <span style="color:#79bbff" v-else-if="scope.row.checkType == 'OffDuty'">下班</span>
        <span v-else>未知</span>
      </template>

      <!-- <template v-slot:toolbar="scope">
          <el-button @click="openPage(scope.row)" link size="small">
            <span v-if="scope.row.status == 0">[编辑]</span>
            <span v-else>[查看]</span>
          </el-button>
        </template> -->
    </static-table>
  </el-card>
</template>
    
<script>
import { fetchSignLog } from "@/api/hrdata";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "signlog",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "realName", label: "员工姓名", sortable: true, width: "100", showOverflowTooltip: true },
          { type: "template", prop: "sourceType", label: "数据来源", width: "130" },

          { type: "template", prop: "checkType", label: "考勤类型", width: "80" },
          { prop: "baseCheckTime", label: "基准时间", width: "150", sortable: true, showOverflowTooltip: true },
          { prop: "userCheckTime", label: "打卡时间", width: "150", sortable: true, showOverflowTooltip: true },

          { type: "template", prop: "locationResult", label: "位置结果", width: "100", sortable: true },
          { type: "template", prop: "timeResult", label: "打卡结果", width: "100", sortable: true },
          { prop: "workDate", label: "工作日", width: "150", sortable: true, showOverflowTooltip: true },

          { prop: "createDate", label: "同步时间", width: "150", sortable: true, },
          { prop: "createUserName", label: "同步人", sortable: true, searchable: true, width: "100", },
          //{ type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "baseCheckTime", //默认的排序字段
        sord: "desc",
      },
      currRow: null,
      dateRange: [],
    };
  },
  mounted() {
    this.fetchs();
  },
  methods: {
    fetchs() {
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      this.querys.filters = [];
      // search.filters = [];
      // if (this.querys.keyword)
      //   search.filters.push({
      //     name: "account",
      //     value: "%" + this.querys.keyword + "%",
      //     operate: "like", 5
      //   });
      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "userCheckTime",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "userCheckTime",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      fetchSignLog(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    openPage(row) {
      this.$router.push({ path: row.formUrl, query: { id: row.id } });
    },
  },
};
</script>